<template>
  <el-table
    :data="tableData"
    style="width: 100%">
    <el-table-column type="expand">
      <template slot-scope="props">
        <el-form label-position="left" inline class="demo-table-expand">
          <el-form-item label="题目ID">
            <span>{{ props.row.id }}</span>
          </el-form-item>
          <el-form-item label="题目目录">
            <span>{{ props.row.dic }}</span>
          </el-form-item>
          <el-form-item label="题目类型">
            <span>{{ props.row.type }}</span>
          </el-form-item>
          <el-form-item label="题干">
            <span>{{ props.row.ques }}</span>
          </el-form-item>
          <el-form-item label="选项A">
            <span>{{ props.row.option_A }}</span>
          </el-form-item>
          <el-form-item label="选项B">
            <span>{{ props.row.option_B }}</span>
          </el-form-item>
          <el-form-item label="选项C">
            <span>{{ props.row.option_C }}</span>
          </el-form-item>
          <el-form-item label="选项D">
            <span>{{ props.row.option_D }}</span>
          </el-form-item>
          <el-form-item label="难易程度">
            <span>{{ props.row.easy }}</span>
          </el-form-item>
          <el-form-item label="正确答案">
            <span>{{ props.row.ans }}</span>
          </el-form-item>
          <el-form-item label="正确次数">
            <span>{{ props.row.correct_count }}</span>
          </el-form-item>
          <el-form-item label="错误次数">
            <span>{{ props.row.wrong_count }}</span>
          </el-form-item>
        </el-form>
      </template>
    </el-table-column>
    <el-table-column
      label="题目ID"
      prop="id">
    </el-table-column>
    <el-table-column
      label="题目"
      prop="ques">
    </el-table-column>
    <el-table-column
      label="正确次数"
      sortable
      prop="correct_count">
    </el-table-column>
    <el-table-column
      label="错误次数"
      sortable  
      prop="wrong_count">
    </el-table-column>
  </el-table>
</template>

<style>
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<script>
  export default {
    data() {
      return {
        tableData: []
      }
    },
    mounted() {
      // 获取整个查询参数对象
      const queryParams = this.$route.query;
      console.log(queryParams);

      // 获取特定的参数
      this.param_username = this.$route.query.username;
      this.param_password = this.$route.query.password;
      this.param_dbname = this.$route.query.dbname;

      const url = `/user/question-table?username=${this.param_username}&password=${this.param_password}&dbname=${this.param_dbname}`;

      this.$axios.get(url).then(response => {
      console.log(response.data);
      this.tableData = response.data;
      }).catch(error => {
          this.$alert('请求题目出现错误，请确认网址传参是否正确', '真是一个致命的问题', {
              confirmButtonText: '确定',
              callback: action => {
                  this.$message({
                  type: 'info',
                  message: `action: ${ action }`
                  });
              }
          });
      }).finally(() => {

      });
  }
}
</script>