import VueRouter from "vue-router";
import Vue from "vue";
import question from "../components/question.vue";
import questionTable from "../components/questionTable.vue";
import startPage from "../components/startPage.vue";


Vue.use(VueRouter);

const routes = new VueRouter({
    routes: [
        {
            path: "/question",
            component: question
        },
        {
            path: "/question-table",
            component: questionTable
        },
        {
            path: "/",
            component: startPage
        }
    ]
    });

export default routes;