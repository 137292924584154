<template>
    <div>
        <el-container>

            <el-header style="font-size: 24px; font-weight: bold; color: white;">
                <span v-if="isLoginMode">登录页面</span>
                <span v-else>注册页面</span>
            </el-header>

            <el-main>
                <el-form :model="form">
                    <!-- 登录表单 -->
                    <div v-if="isLoginMode">
                        <el-form-item label="用户名">
                            <el-input v-model="form.username" autocomplete="on"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input type="password" v-model="form.password" autocomplete="on"></el-input>
                        </el-form-item>
                        <el-form-item label="题库选择">
                            <el-select v-model="form.select_table" placeholder="请选择">
                                <el-option
                                v-for="item in tableData"
                                :key="item.cname"
                                :label="item.pname"
                                :value="item.cname">
                                </el-option>
                            </el-select> 
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="submitForm">登录</el-button>
                        <el-button type="text" @click="toggleMode">没有账号？注册</el-button>
                        </el-form-item>
                    </div>

                    <div v-else>
                        <el-form-item label="用户名">
                        <el-input v-model="form.username" autocomplete="on"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                        <el-input type="password" v-model="form.password" autocomplete="on"></el-input>
                        </el-form-item>
                        <el-form-item label="邀请码">
                        <el-input v-model="form.invitation_code" autocomplete="on"></el-input>
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="submitForm">注册</el-button>
                        <el-button type="text" @click="toggleMode">已有账号？登录</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template> 
<script>
    export default {
        data() {
                return {
                    tableData: [],
                    isLoginMode: true, // 控制显示登录或注册表单
                    form: {
                        username: '',
                        password: '',
                        select_table: '', // 仅登陆时使用
                        invitation_code: '', // 仅注册时使用
                    },
                    password_sha: '',
                }
            },
        
        computed: {
            constructedUrl() {
                return `localhost:8080/#/question?${this.config}`;
            },
            deleteUrl() {
                return `${this.ip}:8088/user/delete?username=wjc`;
            }
        },
        methods: {
            
            toggleMode() {
                this.isLoginMode = !this.isLoginMode;
            },
            async submitForm() {
                if (this.isLoginMode) {
                    if (this.form.select_table == "")
                    {
                        this.$alert('请选择题库', '登录失败', {
                            confirmButtonText: '确定',
                            callback: action => {
                                this.form.username = '';
                                this.form.password = '';
                            }
                        });
                        return;
                    }
                    console.log('登录信息', this.form);
                    const result = await this.processSha256String(this.form.password);
                    // console.log('加密后的密码er', result);
                    const url = `/question?username=${this.form.username}&password=${result}&dbname=${this.form.select_table}`;
                    this.$router.push(url);
                } else {
                    console.log('注册信息', this.form);
                    const result = await this.processSha256String(this.form.password);
                    // console.log('加密后的密码er', result);
                    const register_body = {
                        username: this.form.username,
                        password: result,
                        invitation_code: this.form.invitation_code
                    };

                    const url = `/register`;
                    this.$axios.post(url, register_body).then(response => {
                        
                        const result = response.data;
                        console.log(result);
                        if (result == '200') {
                            this.$alert('点击“确定”返回登录', '注册成功', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    this.isLoginMode = true;
                                }
                            });
                        } else if (result == '409')  {
                            console.log('进入409');
                            this.$alert('用户名已存在', '注册失败', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    this.form.username = '';
                                    this.form.password = '';

                                }
                            });
                        } else if (result == '401')  {
                            this.$alert('邀请码验证失败', '注册失败', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    this.form.invitation_code = '';
                                }
                            });
                        }

                    }).catch(error => {
                        console.log(error);
                    });
                }
            },

            async sha256(str) {

                const CryptoJS = require('crypto-js')
                const encoder = new TextEncoder();
                const data = encoder.encode(str);

                let hashHex;
                if (crypto.subtle) {
                    // 使用 Web Crypto API 计算 SHA-256 散列
                    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                } else {
                    // 使用 crypto-js
                    const hash = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(str));
                    hashHex = hash.toString(CryptoJS.enc.Hex);
                }

                console.log('hashHex', hashHex);
                return hashHex;
            },


            async processSha256String(inputString) {
                try {
                    const hashHex = await this.sha256(inputString);

                    // 在这里进行对 hashHex 的处理，例如添加前缀或后缀
                    const processedString = hashHex;

                    return processedString;
                } catch (error) {
                    console.error(`Error: ${error}`);
                    throw error;
                }
            }
        },
        mounted() {
            console.log('Component is mounted!');
            const url = `/login/table-list`;
            this.$axios.get(url).then(response => {
                this.tableData = response.data;
            }).catch(error => {
                console.log(error);
            });
        }
    }
</script>