import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import routes from './router';
import Vuex from 'vuex';
import store from './store';


Vue.use(Vuex);
Vue.use(ElementUI);
Vue.config.productionTip = false

axios.defaults.baseURL = 'https://api.brush.toycar.com.cn';
// axios.defaults.baseURL = 'http://localhost:8088';
// axios.defaults.baseURL = 'http://172.20.10.4:8088';
Vue.prototype.$axios = axios;

new Vue({
  render: h => h(App),
  store: store,
  router: routes,
}).$mount('#app')
