<template>
    <el-container>
        <el-header height="70px">
            <el-tag class="tag-with-margin" type="warning" :style="{fontSize: head_font_size+'px'}">题目类型: {{ requestData.question.type }}</el-tag>
            <el-tag class="tag-with-margin" type="danger" :style="{fontSize: head_font_size+'px'}">正确{{ requestData.question.correct_count }} / 错误{{ requestData.question.wrong_count }}</el-tag>
            <el-tag class="tag-with-margin" type="success" :style="{fontSize: head_font_size+'px'}">章节号: {{ requestData.question.dic }}</el-tag>
            <el-tag class="tag-with-margin" :style="{fontSize: head_font_size+'px'}" >题目ID: {{ requestData.question.id }}</el-tag>
        </el-header>
        <el-main>

            <el-dialog title="设置" :visible.sync="settingsDialogVisible">
                <el-form :model="form">

                    <el-form-item label="头部字体" :label-width="formLabelWidth"  :step="10">
                        <el-input-number v-model="head_font_size" @change="handleChange" :min="5" :max="30" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="题目字体" :label-width="formLabelWidth">
                        <el-input-number v-model="question_font_size" @change="handleChange" :min="1" :max="100" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="题目高度" :label-width="formLabelWidth">
                        <el-input-number v-model="question_height" @change="handleChange" :min="1" :max="10000" label="描述文字"></el-input-number>
                    </el-form-item> 
                    <el-form-item label="选项字体" :label-width="formLabelWidth">
                        <el-input-number v-model="option_font_size" @change="handleChange" :min="1" :max="100" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="整体宽度" :label-width="formLabelWidth"  :step="10">
                        <el-input-number v-model="formLabelWidth" @change="handleChange" :min="1" :max="10000" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="是否显示输入框" :label-width="formLabelWidth">
                        <el-switch
                        v-model="if_show_input"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-button @click="toPCset();">一键电脑设置</el-button>
                    <el-select v-model="selectedParameterId" placeholder="请选择" @change="updateParameters">
                        <el-option
                            v-for="parameter in parameters"
                            :key="parameter.id"
                            :label="parameter.name"
                            :value="parameter.id">
                        </el-option>
                    </el-select>
                </el-form>
                <div style="display: flex; justify-content: center; align-items: center; height: 7vh;">
                    <div style="display: flex; align-items: center; gap: 10px;">
                        刷题模式：
                        <el-select v-model="param_type" placeholder="模式" :style="{ width: '100px' } " class="tag-with-margin">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>

                        <el-checkbox-group 
                            v-model="checkedType"
                            :min="1"
                            :max="3">
                            <el-checkbox v-for="tp in types" :label="tp" :key="tp">{{tp}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <el-button @click="downloadExcel();">下载作答数据</el-button>
                <el-button @click="exit();">退出登录</el-button>
                <!-- <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                </div> -->
            </el-dialog>

        <div class="center-container">
            <table :style="{ 'max-width': formLabelWidth + 'px' }">
                <thead>
                    <tr>
                        <th :style="{ fontSize: question_font_size + 'px', height: question_height + 'px' }">{{ requestData.question.ques }}</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <div>正在请求{{ this.wait }} 用户等待{{ this.userwait }}</div> -->
                    <tr>
                        <td>
                            <el-button 
                            :type="isSelected('A') ? (showAnser? 'warning': 'success') : 'default'"
                            :class="{ 'noShowAnser': isSelected('A') }"
                            @click="toggleOption('A'); blurButton();"
                            style="white-space: normal; line-height: 1.5; text-align: left;"
                            :style="{fontSize:option_font_size+'px', width: (formLabelWidth-50)+'px'}">
                                A. {{ requestData.question.option_A }}
                            </el-button>
                        </td>
                    </tr>
                    <tr>
                        <td> 
                            <el-button 
                            :type="isSelected('B') ? (showAnser? 'warning': 'success') : 'default'"
                            :class="{ 'noShowAnser': isSelected('B') }"
                            @click="toggleOption('B'); blurButton();"
                            style="white-space: normal; line-height: 1.5;text-align: left;"
                            :style="{fontSize:option_font_size+'px', width: (formLabelWidth-50)+'px'}">
                                B. {{ requestData.question.option_B }}
                            </el-button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <el-button 
                            v-show="requestData.question_type !== 3"
                            :type="isSelected('C') ? (showAnser? 'warning': 'success') : 'default'"
                            :class="{ 'noShowAnser': isSelected('C') }"
                            @click="toggleOption('C'); blurButton();"
                            style="white-space: normal; line-height: 1.5;text-align: left;"
                            :style="{fontSize:option_font_size+'px', width: (formLabelWidth-50)+'px'}">
                                C. {{ requestData.question.option_C }}
                            </el-button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <el-button 
                            v-show="requestData.question_type !== 3"
                            :type="isSelected('D') ? (showAnser? 'warning': 'success') : 'default'"
                            :class="{ 'noShowAnser': isSelected('D') }"
                            @click="toggleOption('D'); blurButton();"
                            style="white-space: normal; line-height: 1.5;text-align: left;"
                            :style="{fontSize:option_font_size+'px', width: (formLabelWidth-50)+'px'}"
                            >
                                D. {{ requestData.question.option_D }}
                            </el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        </el-main>
        <el-footer height="150px">
            <el-row>
                
                <el-button @click="changeQuestion();">换一题</el-button>
                <el-button @click="lastQuestion();">上一题</el-button>
                <el-button @click="cutQuestion();">斩题</el-button>
                
                <el-input v-model="input" placeholder="请输入答案" style="width: 200px;" class="custom-input" v-if="if_show_input" @input="handleInputChange" @keyup.enter.native="handleEnter"></el-input>

                <el-button @click="judgeAnswer()" type="primary">提交答案</el-button>
                <el-button @click="showAnserPri()">{{ showAnswerWord }}</el-button>
                <el-button @click="openSettingsDialog">设置</el-button>
            </el-row>
            <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="calculatePercentage"
            :status="progressStatus"
            :format="progressFormat"
            ></el-progress>
            <div>总答对{{ this.answerInfo.total_true_count }}题，答错{{ this.answerInfo.total_false_count }}题，正确率{{ this.answerInfo.total_accuracy }}% ｜ 今日答对{{ this.answerInfo.today_true_count }}题，答错{{ this.answerInfo.today_false_count }}题，正确率{{ this.answerInfo.today_accuracy }}%</div>
        </el-footer>
    </el-container>
</template>

<style>

/*按钮悬浮*/
    .el-button:hover:not(.noShowAnser) {
        background: #ffffff !important;
        color: #606266 !important;
        border-color: #ffffff !important;
    }


    .el-container {
        height: 100vh;
        width: 100vw;
    }
    .custom-input {
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .tag-with-margin {
    margin-right: 10px; /* 调整间隔的大小 */
    }
    
   .el-header {
    background-color: #384a3c;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
   .el-footer {
    background-color: #b6dabe;
    color: #333;
    text-align: center;
    /* line-height: 150px; */
  }
  
  .el-main {
    background-color: #b6dabe;
    color: #333;
    text-align: center;
    line-height: 130px;
  }
  .el-row {
    height: 70px;
    }   

  .center-container {
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: 100vh; 可根据需要调整高度，这里设置为100%视窗高度 */
    }

  table {
            width: 100%;
            /* max-width: 750px; */
            max-width: var(--formLabelWidth)px;
            border-collapse: collapse;
        }
        th, td {
            border: 1px solid #b6dabe;
            padding: 4px;
            font-size: var(--quesion_font_size)px;
            line-height: 2;
            /* height: 100px; */
        }
        th {
            background-color: #b6dabe;
            text-align: left;
            height: 200px;
        }
</style>

<style scoped>
/* 添加样式以使弹窗可移动 */
.el-dialog__wrapper {
  cursor: move;
}
</style>

<script>
    export default {
        name: 'App',
        data() {
            return {
                requestData: {
                    "username": "loading...",
                    "all_count": 0,
                    "rest_count": 0,
                    "question_type": 0,
                    "question": {
                        "id": 0,
                        "dic": "loading...",
                        "type": "loading...",
                        "ques": "loading...",
                        "ans": "A",
                        "easy": "loading...",
                        "option_A": "loading...",
                        "option_B": "loading...",
                        "option_C": "loading...",
                        "option_D": "loading...",
                        "correct_count": 0,
                        "wrong_count": 0
                    },
                    "soul": "loading..."
                },
                answerInfo:{
                    "total_true_count": 0,
                    "total_false_count": 0,
                    "today_true_count": 0,
                    "today_false_count": 0,
                    "total_accuracy": 0,
                    "today_accuracy": 0
                },
                requestDataNext: null,
                options: [{
                    value: 1,
                    label: '错误率刷题'
                    }, {
                    value: 2,
                    label: '刷完模式'
                    }
                ],
                value: '',
                selectedOptions: [],
                showAnser: false,
                param_username : "",
                param_type : 0,
                param_password : "",
                param_dbname : "",
                
                settingsDialogVisible: false,
                input: '',

                question_font_size: 18,
                question_height: 200,
                formLabelWidth: 350,
                option_font_size: 17,
                head_font_size: 13,
                if_show_input: false,
                lastQuestionId: -1,

                wait: true,
                userwait: true,

                parameters: [],

                showAnswerWord: '查看答案',

                checkedType: ['单选题', '多选题', '判断题'],
                types: ['单选题', '多选题', '判断题'],
            }
        },
        computed: {
            calculatePercentage() {
                // 计算百分比
                const percentage = ((this.requestData.all_count - this.requestData.rest_count) / this.requestData.all_count) * 100;
                return Math.round(percentage);
            },
            progressStatus() {
                // 根据百分比设置进度条的状态
                return this.calculatePercentage === 100 ? 'success' : 'normal';
            },
        },
        methods: {
            async fetchParameters() {
                const url = `/getParameters`;
                this.$axios.get(url).then(response => {
                    this.parameters = response.data;
                }).catch(error => {
                    // 处理错误
                    console.error(error);
                });
            },
            updateParameters() {
                this.selectedParameter = this.parameters.find(param => param.id === this.selectedParameterId);
                if (this.selectedParameter) {
                    this.head_font_size = this.selectedParameter.head_font_size;
                    this.question_font_size = this.selectedParameter.question_font_size;
                    this.question_height = this.selectedParameter.question_height;
                    this.option_font_size = this.selectedParameter.option_font_size;
                    this.formLabelWidth = this.selectedParameter.formLabelWidth;
                }
            },
            lastQuestion() {
                if(this.lastQuestionId == -1){
                    this.$message({
                        message: '这是第一题',
                        type: 'warning'
                    });
                }
                else{
                    const url = `/user/question?username=${this.param_username}&type=${this.param_type}&password=${this.param_password}&dbname=${this.param_dbname}&qid=${this.lastQuestionId}`;
                    this.$axios.get(url).then(response => {
                        console.log(response.data);
                        this.requestData = response.data;
                    }).catch(error => {
                        // 处理错误
                        console.error(error);
                    });
                }
            },
            progressFormat(){
                return `${this.requestData.all_count - this.requestData.rest_count}/${this.requestData.all_count}`;
            },
            exit() {
                this.$router.push('/');
            },
            handleInputChange() {
                // 处理输入框的变化
                console.log(this.input);
                const inputValue = this.input;
                const reversedArray = inputValue.split('').map(char => this.convertToLetter(char));
                console.log(reversedArray);
                // 存储结果到 selectedOptions
                this.selectedOptions = reversedArray;
            },
            convertToLetter(char) {
                // 将数字转换成对应的字母
                if (/^[1-9]$/.test(char)) {
                    const charCode = char.charCodeAt(0);
                    return String.fromCharCode(16 + charCode); 
                } else if (/^[a-zA-Z]$/.test(char)) {
                    return char.toUpperCase();
                } else {
                    return char;
                }
            },
            downloadExcel() {
                // Move the content of the downloadExcel function here
                var xhr = new XMLHttpRequest();
                var url = `https://api.brush.toycar.com.cn/user/export-question-table?username=${this.param_username}&type=${this.param_type}&password=${this.param_password}&dbname=${this.param_dbname}`;
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';

                xhr.onload = function () {
                    console.log(xhr);
                    if (xhr.status === 200) {
                        var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        var fileName = `UserAnswer_${this.param_username}_${this.param_dbname}.xlsx`;
                        link.download = fileName;

                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                    }
                };

                xhr.send();
            },
            handleEnter() {
                // 在这里执行当按下回车键时要执行的操作
                console.log('按下了回车键，当前输入框的值是：', this.input);

                // 可以在这里调用其他函数或执行其他逻辑
                this.judgeAnswer();
            },
            toPCset() {
                this.head_font_size = 23;
                this.formLabelWidth = 1200;
                this.question_font_size = 35;
                this.option_font_size = 26;
                this.question_height = 250;
                this.if_show_input = true;
            },
            isSelected(option) {
                return this.selectedOptions.includes(option);
            },
            openSettingsDialog() {
                this.settingsDialogVisible = true;
            },
            toggleOption(option) {
                const index = this.selectedOptions.indexOf(option);
                if (this.showAnser) {
                    this.judgeAnswer();
                    return;
                }
                if (this.requestData.question_type === 2) {
                    // 多选题
                    if (index !== -1) {
                        // Option is already selected, so remove it
                        this.selectedOptions.splice(index, 1);
                    } else {
                        // Option is not selected, so add it
                        this.selectedOptions.push(option);
                    }
                } else {
                    // 单选题
                    if (index !== -1) {
                        // Option is already selected, so remove it
                        this.selectedOptions.splice(index, 1);
                    } else {
                        // Option is not selected, so add it
                        this.selectedOptions = [option];
                    }
                    this.judgeAnswer();

                }
                console.log(this.selectedOptions);
            }, 
            blurButton() {
                // Force the button to lose focus
                document.activeElement.blur();
            },
            changeQuestion() {
                // this.openFullScreen();
                this.showAnswerWord = '查看答案'
                this.lastQuestionId = this.requestData.question.id;

                if(this.wait == false){
                    this.requestData = this.requestDataNext;
                    this.requestDataNext = null;

                    this.preGetNextData()
                    this.selectedOptions = [];
                    this.showAnser = false;
                    this.input = '';
                }
                else{
                    this.userwait = true;
                    this.openFullScreen();
                }
                
            },

            preGetNextData(){
                const url = `/user/question?username=${this.param_username}&type=${this.param_type}&password=${this.param_password}&dbname=${this.param_dbname}&checkedType=${this.checkedType}`;
                this.wait = true;
                this.userwait = false;
                this.$axios.get(url).then(response => {
                console.log(response.data);
                this.requestData_get = response.data;
                this.wait = false;
                }).catch(error => {
                }).finally(() => {
                    console.log('finally');
                    console.log(this.requestData_get.username);
                    this.closeFullScreen();
                    if (this.requestData_get.username == "用户名或密码错误") {
                        this.$alert('用户名或密码错误，请检查网址传参！', '真是一个致命的问题', {
                            confirmButtonText: '确定',
                            callback: action => {
                                this.$message({
                                type: 'info',
                                message: `action: ${ action }`
                                });
                                
                            }
                        });
                    }
                    else if (this.requestData_get.username == "没有符合条件的题目") {
                        this.$alert('没有符合条件的题目，你已经刷完了', '恭喜', {
                            confirmButtonText: '确定',
                            callback: action => {

                            }
                        });
                    }
                    else{
                        console.log('preGet拿到了一次新的题目，这次userwait是'+this.userwait)
                        if (this.userwait == true){
                            this.userwait = false
                            this.selectedOptions = [];
                            this.showAnser = false;
                            this.requestData = this.requestData_get;
                            this.input = '';
                            this.preGetNextData();
                        }
                        else{
                            this.requestDataNext = this.requestData_get;
                            console.log('下一次请求到的题号为：');
                            console.log(this.requestDataNext.question.id);
                    
                        }
                        
                    }
                });
            },
   
            submitChoice(isCorrect) {
                const username = this.requestData.username;
                const password = this.param_password;
                const qid = this.requestData.question.id;

                // 构建请求的 URL，并将参数添加到查询字符串
                const url = `/user/submitChoice?username=${username}&password=${password}&qid=${qid}&yn=${isCorrect ? 1 : 0}&dbname=${this.param_dbname}`;

                this.$axios.post(url)
                    .then(response => {
                        console.log(response.data);
                        console.log("qingqiuchenggong");
                        // 更新作答统计信息
                        const url1 = `/user/answer-info?username=${username}&password=${password}&dbname=${this.param_dbname}`;
                        
                        this.$axios.get(url1).then(response => {
                            console.log(response.data);
                            this.answerInfo = response.data;
                        }).catch(error => {
                            // 处理错误
                            console.error(error);
                        });
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                    });
            },

            judgeAnswer() {
                // 判断答案是否正确
                const answer = this.requestData.question.ans;
                const sortedSelectedOptions = this.selectedOptions.slice().sort().join('');
                const isEqual = sortedSelectedOptions === answer;
                console.log(isEqual); // 应该输出 true
                if(this.showAnser != true)
                    this.submitChoice(isEqual);
                if (isEqual) {
                    this.changeQuestion();
                } else {
                    this.selectedOptions = answer.split('');
                    this.showAnser = true;
                }
            },
            showAnserPri() {
                console.log(this.requestData.question.ans)
                this.showAnswerWord = this.requestData.question.ans;
            },
            cutQuestion(){
                const cut_i = this.requestData.question.wrong_count * 2 + 1 - this.requestData.question.correct_count 
                for (let index = 0; index < cut_i; index++) {
                    this.submitChoice(true);
                }
                this.changeQuestion();
            },
            openFullScreen() {
                const loading = this.$loading({
                    lock: true,
                    text: this.requestData.soul,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.8)'
                });

            },
            closeFullScreen() {
                const loading = this.$loading();
                setTimeout(() => {
                    loading.close();
                }, 1);
            }
        },
        mounted() {
            // 获取整个查询参数对象
            const queryParams = this.$route.query;
            console.log(queryParams);
            console.log('页面加载完成，开始第一次请求');
            // 获取特定的参数
            this.param_username = this.$route.query.username;
            this.param_type = 1;
            this.param_password = this.$route.query.password;
            this.param_dbname = this.$route.query.dbname;

            const url = `/user/question?username=${this.param_username}&type=${this.param_type}&password=${this.param_password}&dbname=${this.param_dbname}&checkedType=${this.checkedType}`;

            this.$axios.get(url).then(response => {
            console.log(response.data);
            this.requestData_get = response.data;
            }).catch(error => {
                this.$alert('请求题目出现错误，请确认网址传参是否正确', '真是一个致命的问题', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                        type: 'info',
                        message: `action: ${ action }`
                        });
                    }
                });
            }).finally(() => {
                console.log('finally');
                console.log(this.requestData_get.username);
                this.closeFullScreen();
                if (this.requestData_get.username == "用户名或密码错误") {
                    this.$alert('用户名或密码错误，请检查网址传参！', '真是一个致命的问题', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$router.push('/');
                        }
                    });
                }
                else if (this.requestData_get.username == "没有符合条件的题目") {
                    this.$alert('没有符合条件的题目，你已经刷完了', '恭喜', {
                        confirmButtonText: '确定',
                        callback: action => {
                            
                        }
                    });
                }
                else {
                    console.log('第一次请求结束，开始第一次预请求');
                    this.requestData = this.requestData_get;
                    this.userwait = false;
                    this.preGetNextData();

                    const url1 = `/user/answer-info?username=${this.param_username}&password=${this.param_password}&dbname=${this.param_dbname}`;
                    this.$axios.get(url1).then(response => {
                        console.log(response.data);
                        this.answerInfo = response.data;
                    }).catch(error => {
                        // 处理错误
                        console.error(error);
                    });
                }
            });
            this.fetchParameters();
        },
        created: function() {

        },
    }
    
</script>